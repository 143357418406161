import {
    Address,
    AddressValue,
    ArgSerializer,
    BigUIntValue,
    ResultsParser,
    StringValue,
    TokenIdentifierValue,
    TokenPayment,
    TokenTransfer,
    Transaction,
    TypedValue,
    U32Value,
    U64Value,
    U8Value,
} from '@multiversx/sdk-core/out';
import BigNumber from 'bignumber.js';
import { CLAIM_REWARDS_GAS_LIMIT, MINT_VESTA_GAS_LIMIT, STAKE_LPS_GAS_LIMIT, VESTA_MINTER_SC_ADDRESS } from 'config';
import { FarmParametersType, LpTokenContextType, StakedNFTsUIStatisticsType, VestingTypeEnum } from 'z/types';
import { convertWeiToEsdt, toastError, ZERO_STRING } from 'z/utils';
import { elrondDappSendTransactions, mvxQuery } from './common';
import { createFarmSmartContract, elrondProvider } from './provider';

export async function getPublicMultiplier(farmScAddress: string): Promise<number> {
    try {
        const contract = createFarmSmartContract(farmScAddress);
        const interaction = contract.methodsExplicit.getGlobalLiquidityMultiplier();
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );

        if (!firstValue || !returnCode.isSuccess()) {
            toastError(returnMessage);
            return 1;
        }

        const value = firstValue.valueOf();
        return value.toNumber();
    } catch (err) {
        console.log(err);
    }
    return 1;
}

export async function getPersonalMultiplier(farmScAddress: string, userAddress: string): Promise<number> {
    try {
        const contract = createFarmSmartContract(farmScAddress);
        const interaction = contract.methodsExplicit.getPersonalLiquidityMultiplier([
            new AddressValue(new Address(userAddress)),
        ]);
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );

        if (!firstValue || !returnCode.isSuccess()) {
            toastError(returnMessage);
            return 1;
        }

        const value = firstValue.valueOf();
        return value.toNumber();
    } catch (err) {
        console.log(err);
    }
    return 1;
}

export async function getStakedNfts(farmScAddress: string, userAddress: string): Promise<StakedNFTsUIStatisticsType> {
    const defaultValue = {
        total_staked_lm_nfts_bronze: 0,
        total_staked_lm_nfts_silver: 0,
        total_staked_lm_nfts_gold: 0,
        total_staked_pm_nfts_bronze_by_user: 0,
        total_staked_pm_nfts_silver_by_user: 0,
        total_staked_pm_nfts_gold_by_user: 0,
        total_pm_staked_bronze_by_everyone: 0,
        total_pm_staked_silver_by_everyone: 0,
        total_pm_staked_gold_by_everyone: 0,
        unstake_timestamp: 0,
        can_unstake: false,
    };

    try {
        const contract = createFarmSmartContract(farmScAddress);
        const interaction = contract.methodsExplicit.getAllStakedNfts([new AddressValue(new Address(userAddress))]);
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );

        if (!firstValue || !returnCode.isSuccess()) {
            throw Error(returnMessage);
        }

        const value = firstValue.valueOf();
        const decoded = {
            total_staked_lm_nfts_bronze: value.total_staked_lm_nfts_bronze.toNumber(),
            total_staked_lm_nfts_silver: value.total_staked_lm_nfts_silver.toNumber(),
            total_staked_lm_nfts_gold: value.total_staked_lm_nfts_gold.toNumber(),
            total_staked_pm_nfts_bronze_by_user: value.total_staked_pm_nfts_bronze_by_user.toNumber(),
            total_staked_pm_nfts_silver_by_user: value.total_staked_pm_nfts_silver_by_user.toNumber(),
            total_staked_pm_nfts_gold_by_user: value.total_staked_pm_nfts_gold_by_user.toNumber(),
            total_pm_staked_bronze_by_everyone: value.total_pm_staked_bronze_by_everyone.toNumber(),
            total_pm_staked_silver_by_everyone: value.total_pm_staked_silver_by_everyone.toNumber(),
            total_pm_staked_gold_by_everyone: value.total_pm_staked_gold_by_everyone.toNumber(),
            unstake_timestamp: value.unstake_timestamp.toNumber(),
            can_unstake: value.can_unstake,
        };

        return decoded;
    } catch (err) {
        console.error(err);
    }

    return defaultValue;
}

export async function stakeLps(
    farmAddress: string,
    lpTokenId: string,
    amount: BigNumber,
    lockType: number,
    chainID: string,
) {
    const tokenPayments: TokenPayment = TokenPayment.fungibleFromBigInteger(lpTokenId, amount);
    const args: TypedValue[] = [new U8Value(lockType)];

    const contract = createFarmSmartContract(farmAddress);
    const txx: Transaction = contract.methodsExplicit
        .stake(args)
        .withSingleESDTTransfer(tokenPayments)
        .withGasLimit(STAKE_LPS_GAS_LIMIT)
        .withChainID(chainID)
        .buildTransaction();
    const tx = {
        value: 0,
        data: txx.getData().toString(),
        receiver: farmAddress,
        gasLimit: STAKE_LPS_GAS_LIMIT,
    };

    const txName = 'Stake';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function farmUnstake(farmAddress: string, payments: TokenTransfer[], sender: string) {
    const args: TypedValue[] = [new AddressValue(new Address(farmAddress)), new U32Value(payments.length)];
    payments.map((payment) => {
        args.push(new TokenIdentifierValue(payment.tokenIdentifier));
        args.push(new U64Value(payment.nonce));
        args.push(new BigUIntValue(payment.amountAsBigInteger));
    });
    args.push(new StringValue('unstake'));

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `MultiESDTNFTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: sender,
        gasLimit: 75_000_000 + payments.length * 17_500_000,
    };

    const txName = 'Unstake';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function farmUnbond(farmAddress: string, payments: TokenTransfer[], sender: string) {
    const args: TypedValue[] = [new AddressValue(new Address(farmAddress)), new U32Value(payments.length)];
    payments.map((payment) => {
        args.push(new TokenIdentifierValue(payment.tokenIdentifier));
        args.push(new U64Value(payment.nonce));
        args.push(new BigUIntValue(payment.amountAsBigInteger));
    });
    args.push(new StringValue('claimUnbonded'));

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `MultiESDTNFTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: sender,
        gasLimit: STAKE_LPS_GAS_LIMIT,
    };

    const txName = 'Unbond';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function getPendingRewards(
    farmScAddress: string,
    caller: string,
    lpTokenContexts: LpTokenContextType[],
): Promise<string[]> {
    try {
        const args = [caller, lpTokenContexts];
        const contract = createFarmSmartContract(farmScAddress);
        const interaction = contract.methods.getPendingRewards(args);
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );

        if (!firstValue || !returnCode.isSuccess()) {
            throw Error(returnMessage);
        }

        const value = firstValue.valueOf();
        const decoded = [value.field0.toFixed(0), value.field0.toFixed(1)];

        return decoded;
    } catch (err) {
        console.error('getPendingRewards: failed', err);
    }

    return [ZERO_STRING, ZERO_STRING];
}

export async function claimRewards(
    sender: string,
    farmScAddress: string,
    vestingType: VestingTypeEnum,
    lockYears: number,
    payments: TokenTransfer[],
) {
    const args: TypedValue[] = [
        new AddressValue(new Address(farmScAddress)),
        new U32Value(payments.length), // number of payments
    ];
    payments.forEach((payment) =>
        args.push(
            new TokenIdentifierValue(payment.tokenIdentifier),
            new U64Value(payment.nonce),
            new BigUIntValue(payment.amountAsBigInteger),
        ),
    );
    args.push(
        new StringValue('claimRewards'), // method name
        new U32Value(vestingType),
        new U8Value(lockYears),
    );

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `MultiESDTNFTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: sender,
        gasLimit: 40_000_000 + 10_000_000 * payments.length,
    };

    const txName = 'Claim Rewards';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function farmClaimRewards(
    sender: string,
    farmScAddress: string,
    vestingType: VestingTypeEnum,
    lockYears: number,
    payments: TokenTransfer[],
    mintPercent: number,
) {
    const args: TypedValue[] = [
        new AddressValue(new Address(farmScAddress)),
        new U32Value(payments.length), // number of payments
    ];
    payments.forEach((payment) =>
        args.push(
            new TokenIdentifierValue(payment.tokenIdentifier),
            new U64Value(payment.nonce),
            new BigUIntValue(payment.amountAsBigInteger),
        ),
    );
    args.push(
        new StringValue('claim'), // method name
        new U32Value(vestingType),
        new U8Value(lockYears),
        new U64Value(mintPercent * 100), // 10000 = 100%
    );

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `MultiESDTNFTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: sender,
        gasLimit: 100_000_000 + 10_000_000 * payments.length,
    };

    const txName = 'Claim Rewards';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function getFarmParameters(
    farmScAddress: string,
    userAddress?: string,
): Promise<FarmParametersType | undefined> {
    try {
        const args = [];
        if (userAddress) {
            args.push(userAddress);
        }
        const contract = createFarmSmartContract(farmScAddress);
        const interaction = contract.methods.getFarmParameters(args);
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );

        if (!firstValue || !returnCode.isSuccess()) {
            throw Error(returnMessage);
        }

        const value = firstValue.valueOf();
        const decoded = {
            is_tier_x: value.is_tier_x,
            diamond_multiplier: convertWeiToEsdt(value.diamond_multiplier).toNumber(),
            tier_multiplier: convertWeiToEsdt(value.tier_multiplier).toNumber(),
            pool_weight: convertWeiToEsdt(value.pool_weight).toNumber(),
            liquidity_multiplier: convertWeiToEsdt(value.liquidity_multiplier).toNumber(),
            personal_multiplier: convertWeiToEsdt(value.personal_multiplier).toNumber(),
            total_multiplier: convertWeiToEsdt(value.total_multiplier).toNumber(),
        };

        return decoded;
    } catch (err) {
        console.error('getFarmParameters: failed', err);
        return undefined;
    }
}

export async function elrondFixBrokenVlps(sender: string, farmScAddress: string, payments: TokenTransfer[]) {
    const args: TypedValue[] = [
        new AddressValue(new Address(farmScAddress)),
        new U32Value(payments.length), // number of payments
    ];
    payments.forEach((payment) =>
        args.push(
            new TokenIdentifierValue(payment.tokenIdentifier),
            new U64Value(payment.nonce),
            new BigUIntValue(payment.amountAsBigInteger),
        ),
    );
    args.push(
        new StringValue('fix'), // method name
    );

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `MultiESDTNFTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: sender,
        gasLimit: STAKE_LPS_GAS_LIMIT,
    };

    const txName = 'Fix Broken vLP';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function getSftCollection(farmScAddress: string): Promise<string> {
    try {
        const contract = createFarmSmartContract(farmScAddress);
        const interaction = contract.methods.getStakeTokenIdentifier();
        const query = interaction.check().buildQuery();
        const queryResponse = await elrondProvider.queryContract(query);
        const endpointDefinition = interaction.getEndpoint();
        const { firstValue, returnCode, returnMessage } = new ResultsParser().parseQueryResponse(
            queryResponse,
            endpointDefinition,
        );

        if (!firstValue || !returnCode.isSuccess()) {
            throw Error(returnMessage);
        }

        const value = firstValue.valueOf();
        const decoded = value.toString();

        return decoded;
    } catch (err) {
        console.error('getSftCollection: failed', err);
        return '';
    }
}

export async function stakeSfts(
    sender: string,
    farmScAddress: string,
    payments: TokenTransfer[],
    universalMode: boolean,
) {
    const args: TypedValue[] = [
        new AddressValue(new Address(farmScAddress)),
        new U32Value(payments.length), // number of payments
    ];
    payments.forEach((payment) =>
        args.push(
            new TokenIdentifierValue(payment.tokenIdentifier),
            new U64Value(payment.nonce),
            new BigUIntValue(payment.amountAsBigInteger),
        ),
    );
    args.push(
        new StringValue(universalMode ? 'stakeGlobalPool' : 'stakePersonalPool'), // method name
    );

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `MultiESDTNFTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: sender,
        gasLimit: STAKE_LPS_GAS_LIMIT,
    };

    const txName = 'Stake SFT';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function unstakeSfts(farmScAddress: string, payments: TokenTransfer[], universalMode: boolean) {
    const args: TypedValue[] = [];
    payments.forEach((payment) => args.push(new U64Value(payment.nonce), new BigUIntValue(payment.amountAsBigInteger)));

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `${universalMode ? 'unstakeGlobalPool' : 'unstakePersonalPool'}@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: farmScAddress,
        gasLimit: STAKE_LPS_GAS_LIMIT,
    };

    const txName = 'Unstake SFT';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

/*
  pub enum VestingType {
    Invalid, // 0
    Vesta,    // 1
    SleepingVesta,  // 2
    FrozenVesta   // 3
  }
*/

export async function claimWithRawVesta(payment: TokenTransfer, vestingType: VestingTypeEnum, lockYears: number) {
    const args: TypedValue[] = [
        new TokenIdentifierValue(payment.tokenIdentifier),
        new BigUIntValue(payment.amountAsBigInteger),
        new StringValue('claimWithRawVesta'),
        new U32Value(vestingType), // vesting_type: VestingType,
    ];
    if (vestingType == 2) {
        // sVESTA
        args.push(new U8Value(lockYears));
    }

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `ESDTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: VESTA_MINTER_SC_ADDRESS,
        gasLimit: MINT_VESTA_GAS_LIMIT,
    };

    const txName = vestingType == 2 ? 'Mint sVESTA' : vestingType == 3 ? 'Mint fVESTA' : 'Mint VESTA';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function claimUnlockedSleepingVesta(payment: TokenTransfer, sender: string) {
    const args: TypedValue[] = [
        new TokenIdentifierValue(payment.tokenIdentifier),
        new U64Value(payment.nonce),
        new BigUIntValue(payment.amountAsBigInteger),
        new AddressValue(new Address(VESTA_MINTER_SC_ADDRESS)),
        new StringValue('claimUnlockedSleepingVesta'),
    ];

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `ESDTNFTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: sender,
        gasLimit: MINT_VESTA_GAS_LIMIT,
    };

    const txName = 'Unlock';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function mergeSleepingVesta(payments: TokenTransfer[], sender: string) {
    const args: TypedValue[] = [new AddressValue(new Address(VESTA_MINTER_SC_ADDRESS)), new U32Value(payments.length)];
    payments.map((payment) => {
        args.push(new TokenIdentifierValue(payment.tokenIdentifier));
        args.push(new U64Value(payment.nonce));
        args.push(new BigUIntValue(payment.amountAsBigInteger));
    });
    args.push(new StringValue('mergeSleepingVesta'));

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `MultiESDTNFTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: sender,
        gasLimit: CLAIM_REWARDS_GAS_LIMIT,
    };

    const txName = 'Merge sVST';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function farmMerge(farmAddress: string, payments: TokenTransfer[], sender: string) {
    const args: TypedValue[] = [new AddressValue(new Address(farmAddress)), new U32Value(payments.length)];
    payments.map((payment) => {
        args.push(new TokenIdentifierValue(payment.tokenIdentifier));
        args.push(new U64Value(payment.nonce));
        args.push(new BigUIntValue(payment.amountAsBigInteger));
    });
    args.push(new StringValue('mergeTokens'));

    const { argumentsString } = new ArgSerializer().valuesToString(args);
    const data = `MultiESDTNFTTransfer@${argumentsString}`;

    const tx = {
        value: 0,
        data,
        receiver: sender,
        gasLimit: CLAIM_REWARDS_GAS_LIMIT,
    };

    const txName = 'Merge';
    const { sessionId, error } = await elrondDappSendTransactions(tx, txName);

    return { sessionId, error };
}

export async function farmGetSwapPoolOwner(farmAddress: string): Promise<string> {
    try {
        const farmContract = createFarmSmartContract(farmAddress);
        const value = await mvxQuery(farmContract.methods.getSwapPoolOwner());
        const decoded = value.toString();

        return decoded;
    } catch (err) {
        console.error('farmGetSwapPoolOwner:', err);
        return '';
    }
}
