import React, { useEffect, useState } from 'react';
import { TokenTransfer } from '@multiversx/sdk-core/out';
import { useGetAccountInfo, useGetPendingTransactions } from '@multiversx/sdk-dapp/hooks';
import { Loader } from '@multiversx/sdk-dapp/UI';
import clsx from 'clsx';
import { Row, Col } from 'react-bootstrap';
import Countdown from 'react-countdown';
import { BiLeftArrowAlt } from 'react-icons/bi';
import { FaWeightHanging } from 'react-icons/fa';
import { useNavigate, useParams } from 'react-router-dom';

import rVestaXLogo from 'assets/img/07_RawVesta_200x200.svg';
import vestaXLogo from 'assets/img/08_Vesta_200x200.svg';
import sVestaXLogo from 'assets/img/09_Sleeping-Vesta_200x200.svg';
import fVestaXLogo from 'assets/img/10_Frozen-Vesta_200x200.svg';
import cVestaXLogo from 'assets/img/11_Condenssed Vesta_200x200.svg';
import bVestaXLogo from 'assets/img/12_Blessed-Vesta_200x200.svg';
import densitySymbol from 'assets/img/density-symbol.png';
import imgPotencyDust from 'assets/img/potency/00_Dust.svg';
import imgPotencyDirt from 'assets/img/potency/01_DIRT.svg';
import imgPotencyIron from 'assets/img/potency/02_Iron.svg';
import imgPotencyBronze from 'assets/img/potency/03_Bronze.svg';
import imgPotencySilver from 'assets/img/potency/04_Silver.svg';
import imgPotencyGold from 'assets/img/potency/05_Gold.svg';
import imgPotencyPlatinum from 'assets/img/potency/06_Platinum.svg';
import imgPotencyDiamond1 from 'assets/img/potency/07_Diamond_01.svg';
import imgPotencyDiamond2 from 'assets/img/potency/07_Diamond_02.svg';
import imgPotencyDiamond3 from 'assets/img/potency/07_Diamond_03.svg';

import { InfoTooltip } from 'components';
import { CountdownCompleted, countdownRenderer } from 'components/CountdownComponents';
import { CustomSlider } from 'components/CustomSlider';
import { FARMS, FARM_TOKEN_UNLOCK_EPOCHS_MAP, VLP_WEIGHTS, getSlipingVestaMultiplier } from 'config';
import {
  selectFarm,
  selectSwap,
} from 'redux/reducers';
import { useAppSelector } from 'redux/store';
import { routeNames } from 'routes';
import { getTokenLogo } from 'z/elrond';
import {
    eatGetEliteAccountTier,
    eatGetFvstMultiplier,
    farmClaimRewards,
    farmGetSwapPoolOwner,
    getAccountNftsByCollection,
    getFarmParameters,
    getPendingRewards,
    getSftCollection,
    getStakedNfts,
    getTokenBalanceFromApi,
    getTokenSupplyFromApi,
    stakeSfts,
    unstakeSfts,
} from 'z/elrond';
import { useVestaCommonHooks } from 'z/hooks';
import {
    FarmParametersType,
    FarmTokenType,
    LpTokenContextType,
    NonFungibleTokenBalanceType,
    StakedNFTsUIStatisticsType,
    VestingTypeEnum,
} from 'z/types';
import {
    formatNumber,
    convertSecondsToLocalDateTime,
    convertToDollarString,
    createTokenTicker,
    convertToShortString,
    convertWeiToEsdt,
    decodeAttributes,
    DEFAULT_DECIMALS,
    ERROR_CONNECT_WALLET,
    ERROR_SC_DATA_NOT_LOADED,
    ERROR_TRANSACTION_ONGOING,
    getLPTokenRatio,
    parseBigNumber,
    toastError,
    ZERO_STRING,
} from 'z/utils';
import { FarmRowInfo } from './FarmRowInfo';
import { StakeLpsBox } from './StakeLPsBox';

function convertFTT2NFTBT(token: FarmTokenType): NonFungibleTokenBalanceType {
    return {
        ...token,
        stake_epoch: token.attributes.stake_epoch,
        unbonding_start_epoch: token.attributes.unbonding_start_epoch,
        stake_token_type: token.attributes.locked_lp_type_id,
        attributes: '',
        ticker: createTokenTicker(token.collection),
        balance: token.amount,
    };
}

const vestaRewardsSliderMarks = [
    { value: 0, label: '0' },
    { value: 25, label: '25%' },
    { value: 50, label: '50%' },
    { value: 75, label: '75%' },
    { value: 100, label: '100%' },
];

export const FarmLiquidity = () => {
    const navigate = useNavigate();
    const { hasPendingTransactions } = useGetPendingTransactions();
    const { paramFarmId } = useParams();
    const { getTokenPrice } = useVestaCommonHooks();

    const swapRedux = useAppSelector(selectSwap);
    const farmRedux = useAppSelector(selectFarm);

    const farmId = parseInt(paramFarmId ?? '0');
    const farm = FARMS[farmId];
    const pool = swapRedux.pools ? swapRedux.pools.find((_pool) => _pool.pool_address == farm.pool_address) : undefined;

    const [circulatingLpSupply, setCirculatingLpSupply] = useState<string>('0');

    const [availableVegldAmount, setVegldAmount] = useState<string>('0');
    // const [availableExoticLpsAmount, setAvailableExoticLpsAmount] = useState<number>(0);
    const [totalExoticLps, setTotalExoticLps] = useState<number>(0);
    const [totalAccountPoolLps, setTotalAccountPoolLps] = useState<number>(0);

    const [available_token_amount, setAvailableTokenAmount] = useState<string>('0');
    // const [exoticLps, setExoticLps] = useState<NonFungibleTokenBalanceType[]>([]);
    const { address } = useGetAccountInfo();

    const [vestaSleepingYears, setVestaSleepingYears] = useState<number>(1);

    const [poolDensity] = useState<number>(farm.pool_density);
    const [poolDiamond_carats, setPoolDiamondCarats] = useState<number>(0);
    const [poolPotency, setPoolPotency] = useState<string>('');
    const [poolPotencyImage, setPoolPotencyImage] = useState<string>('');
    const [farmParameters, setFarmParameters] = useState<FarmParametersType>();
    const [poolTier, setPoolTier] = useState<number>(0);

    // const [stakedPersonalPoolNfts, setStakedPersonalPoolNfts] = useState<any>({
    //   1: ZERO_STRING,
    //   2: ZERO_STRING,
    //   3: ZERO_STRING,
    // });
    // const [stakedPublicPoolNfts, setStakedPublicPoolNfts] = useState<any>({
    //   1: ZERO_STRING,
    //   2: ZERO_STRING,
    //   3: ZERO_STRING,
    // });
    const [stakedSftCounts, setStakedSftCounts] = useState<number[]>(Array(6).fill(0));
    const [selectedSftTier, setSelectedSftTier] = useState<number>(0);
    const [sftCollection, setSftCollection] = useState<string>('');
    const [sftBalances, setSftBalances] = useState<number[]>(Array(3).fill(0));
    const [targetSftCount, setTargetSftCount] = useState<number>(0);

    const [totalVlpStaked, setTotalVlpStaked] = useState<number>(0);
    const [vestaMultiplier, setVestaMultiplier] = useState<number>(6);

    const [, setIsLoading] = useState(true);
    const [showExoticLiqStakeBox, setShowExoticLiqStakeBox] = useState<boolean>(false);

    const [mintOption, setMintOption] = useState<number>(0); // 0 for sVESTA, 1 for fVESTA
    const [, setBaseRewardAmount] = useState<string>(ZERO_STRING);
    const [finalRewardAmount, setFinalRewardAmount] = useState<string>(ZERO_STRING);
    const [mintPercent, setMintPercent] = useState<number>(0);

    const [farmTokens, setFarmTokens] = useState<FarmTokenType[]>([]);
    const [stakedSftData, setStakedSftData] = useState<StakedNFTsUIStatisticsType>();

    const [SftCountdown, setSftCountdown] = useState<any>();

    const [claimOption, setClaimOption] = useState<number>(0); // 0 for RAWVST, 1 for CNDVST

    const [fvstMultiplier, setFvstMultiplier] = useState<number>(0);

    function onClickSftTier(index: number) {
        setSelectedSftTier(index);
    }

    useEffect(() => {
        (async () => {
            const _sftCollection = await getSftCollection(farm.farm_address);
            setSftCollection(_sftCollection);
        })();

        (async () => {
            const _swapPoolOwner = await farmGetSwapPoolOwner(farm.farm_address);
            if (!_swapPoolOwner) return;
            const _poolTier = await eatGetEliteAccountTier(_swapPoolOwner);
            setPoolTier(_poolTier);
        })();
    }, []);

    useEffect(() => {
        (async () => {
            if (farmRedux.eliteAccountTier > 3) {
                setFvstMultiplier(await eatGetFvstMultiplier(farmRedux.eliteAccountTier));
            }
        })();
    }, [farmRedux]);

    useEffect(() => {
        setTargetSftCount(stakedSftCounts[selectedSftTier]);
    }, [selectedSftTier, sftBalances, stakedSftCounts]);

    function onChangeTargetSftCount(value: number) {
        if (value < 0) return;
        if (value > sftBalances[selectedSftTier % 3] + stakedSftCounts[selectedSftTier]) return;

        setTargetSftCount(value);
    }

    useEffect(() => {
        if (hasPendingTransactions) return;
        (async () => {
            const _farmParameters = await getFarmParameters(farm.farm_address, address);
            setFarmParameters(_farmParameters);
        })();

        loadStakedNfts();
    }, [address, hasPendingTransactions]);

    useEffect(() => {
        if (!address) return;

        if (pool && pool.lp_token_id) {
            loadAllData().then(() => setIsLoading(false));
        }
    }, [address, farmTokens]);

    useEffect(() => {
        if (!address || hasPendingTransactions) return;

        // fetch Farm tokens in User wallet
        (async () => {
            const _farmTokens: FarmTokenType[] = [];
            const metaEsdts = await getAccountNftsByCollection(address, farm.staked_lp_token);
            for (const metaEsdt of metaEsdts) {
                const decoded = decodeAttributes(metaEsdt, farm.farm_address);
                _farmTokens.push({
                    collection: metaEsdt.collection,
                    nonce: metaEsdt.nonce,
                    identifier: metaEsdt.identifier,
                    decimals: metaEsdt.decimals ?? DEFAULT_DECIMALS,
                    amount: metaEsdt.balance,
                    attributes: decoded,
                });
            }
            setFarmTokens(_farmTokens);

            //
            if (_farmTokens.length > 0) {
                const lpTokenContexts: LpTokenContextType[] = [];
                for (const farmToken of _farmTokens) {
                    lpTokenContexts.push({
                        token_nonce: farmToken.nonce,
                        amount: farmToken.amount,
                        token_type_id: farmToken.attributes.locked_lp_type_id,
                        stake_epoch: farmToken.attributes.stake_epoch,
                        unbonding_epoch: farmToken.attributes.unbonding_start_epoch,
                        mint_timestamp: farmToken.attributes.mint_timestamp,
                        unbonding_claim_epoch: farmToken.attributes.unbonding_start_epoch
                            ? farmToken.attributes.unbonding_start_epoch +
                              FARM_TOKEN_UNLOCK_EPOCHS_MAP[farmToken.attributes.locked_lp_type_id]
                            : 0,
                        can_be_unstaked: true,
                        can_be_claimed: true,
                    });
                }
                const rewards = await getPendingRewards(farm.farm_address, address, lpTokenContexts);

                setBaseRewardAmount(rewards[0]);
                setFinalRewardAmount(rewards[1]);
            } else {
                setBaseRewardAmount(ZERO_STRING);
                setFinalRewardAmount(ZERO_STRING);
            }
        })();
    }, [address, hasPendingTransactions]);

    useEffect(() => {
        if (!sftCollection || !address || hasPendingTransactions) return;

        (async () => {
            const sfts = await getAccountNftsByCollection(address, sftCollection);
            const _sftBalances = [];
            for (let nonce = 1; nonce <= 3; nonce++) {
                const _sft = sfts.find((sft) => sft.nonce == nonce);
                _sftBalances.push(_sft ? Number(_sft.balance) : 0);
            }
            setSftBalances(_sftBalances);
        })();
    }, [sftCollection, address, hasPendingTransactions]);

    useEffect(() => {
        if (!pool) return;

        const vegldReserve = convertWeiToEsdt(pool.first_token_reserve).toNumber();
        let potency = '';
        let potencyImage = '';
        if (vegldReserve < 100) {
            potency = 'Dust';
            potencyImage = imgPotencyDust;
        } else if (vegldReserve < 250) {
            potency = 'Dirt';
            potencyImage = imgPotencyDirt;
        } else if (vegldReserve < 500) {
            potency = 'Iron';
            potencyImage = imgPotencyIron;
        } else if (vegldReserve < 1000) {
            potency = 'Bronze';
            potencyImage = imgPotencyBronze;
        } else if (vegldReserve < 2500) {
            potency = 'Silver';
            potencyImage = imgPotencySilver;
        } else if (vegldReserve < 5000) {
            potency = 'Gold';
            potencyImage = imgPotencyGold;
        } else if (vegldReserve < 10000) {
            potency = 'Platinum';
            potencyImage = imgPotencyPlatinum;
        } else {
            potency = 'Diamond';
            const carat = Math.floor(vegldReserve / 10000);
            setPoolDiamondCarats(carat);

            if (carat == 1) {
                potencyImage = imgPotencyDiamond1;
            } else if (carat == 2) {
                potencyImage = imgPotencyDiamond2;
            } else {
                potencyImage = imgPotencyDiamond3;
            }
        }

        setPoolPotency(potency);
        setPoolPotencyImage(potencyImage);
    }, [pool]);

    useEffect(() => {
        let vm = 1;
        if (mintOption === 1) {
            vm = getSlipingVestaMultiplier(vestaSleepingYears);
        } else if (mintOption === 2) {
            vm = fvstMultiplier;
        }
        setVestaMultiplier(vm);
    }, [mintOption, vestaSleepingYears, fvstMultiplier]);

    const reduce = (items: NonFungibleTokenBalanceType[]): number => {
        if (items?.length === 0) return 0;
        return items
            .reduce((prev, crt) => (prev = prev.plus(convertWeiToEsdt(crt.balance))), convertWeiToEsdt('0'))
            .toNumber();
    };

    const loadAllData = async () => {
        if (!pool) return;

        const totalSupply = await getTokenSupplyFromApi(pool.lp_token_id);
        setCirculatingLpSupply(totalSupply?.supply ?? '0');

        const _firstTokenBalanceInfo = await getTokenBalanceFromApi(address, pool.first_token_id);
        const _secondTokenBalanceInfo = await getTokenBalanceFromApi(address, pool.second_token_id);

        _firstTokenBalanceInfo && setVegldAmount(_firstTokenBalanceInfo.balance);
        _secondTokenBalanceInfo && setAvailableTokenAmount(_secondTokenBalanceInfo.balance);

        const all_staked_lps = farmTokens.map((v) => convertFTT2NFTBT(v));
        const totalExoticLpsAmount = reduce(all_staked_lps);

        const total_vlp = computeVlp(all_staked_lps);
        setTotalExoticLps(totalExoticLpsAmount);
        setTotalAccountPoolLps(totalExoticLpsAmount + convertWeiToEsdt(pool.lp_token_balance).toNumber());

        setTotalVlpStaked(total_vlp);
    };

    const computeVlp = (lps: NonFungibleTokenBalanceType[]) => {
        let total_vlp = 0;
        for (let i = 1; i <= 6; i++) {
            const crt_stake_type = lps.filter((lp) => lp.stake_token_type === i);
            if (crt_stake_type.length === 0) {
                continue;
            }
            total_vlp += reduce(crt_stake_type) * VLP_WEIGHTS[i - 1];
        }
        return total_vlp;
    };

    const loadStakedNfts = async () => {
        if (!address) return;

        const stakedNfts = await getStakedNfts(farm.farm_address, address);
        const _stakedSftCounts = [
            stakedNfts.total_staked_lm_nfts_gold,
            stakedNfts.total_staked_lm_nfts_silver,
            stakedNfts.total_staked_lm_nfts_bronze,

            stakedNfts.total_staked_pm_nfts_gold_by_user,
            stakedNfts.total_staked_pm_nfts_silver_by_user,
            stakedNfts.total_staked_pm_nfts_bronze_by_user,
        ];

        setStakedSftCounts(_stakedSftCounts);
        setStakedSftData(stakedNfts);

        const unstakeTime = stakedNfts.unstake_timestamp * 1000;
        const CountdownWrapper =
            unstakeTime < Date.now()
                ? () => <CountdownCompleted />
                : () => <Countdown renderer={countdownRenderer} date={unstakeTime} autoStart />;
        const MemoCountdown = React.memo(CountdownWrapper);
        setSftCountdown(MemoCountdown);
    };

    // const onChangeVestaRewardsSlider = (e: number) => {
    //   // setVestaRewards(e);
    // };

    const onChangeVestaSleepingYearsSlider = (e: number) => {
        setVestaSleepingYears(e);
    };

    async function onMintVesta() {
        let error = '';
        if (!address) {
            error = ERROR_CONNECT_WALLET;
        } else if (farmTokens.length === 0) {
            error = 'No farm token';
        } else if (hasPendingTransactions) {
            error = ERROR_TRANSACTION_ONGOING;
        }

        if (error) {
            toastError(error);
            return;
        }

        const vestingType =
            mintOption === 2
                ? VestingTypeEnum.FrozenVesta
                : mintOption === 1
                ? VestingTypeEnum.SleepingVesta
                : VestingTypeEnum.Vesta;
        const lockYears = mintOption === 1 ? vestaSleepingYears : 0;
        const payments: TokenTransfer[] = farmTokens.map((farmToken: FarmTokenType) =>
            TokenTransfer.metaEsdtFromBigInteger(
                farmToken.collection,
                farmToken.nonce,
                parseBigNumber(farmToken.amount),
            ),
        );

        await farmClaimRewards(address, farm.farm_address, vestingType, lockYears, payments, mintPercent);
    }

    async function onSftStakeOrUnstake() {
        const nonce = (selectedSftTier % 3) + 1;
        if (targetSftCount > stakedSftCounts[selectedSftTier]) {
            // Stake SFT
            const payments: TokenTransfer[] = [
                TokenTransfer.metaEsdtFromBigInteger(
                    sftCollection,
                    nonce,
                    targetSftCount - stakedSftCounts[selectedSftTier],
                ),
            ];

            await stakeSfts(
                address,
                farm.farm_address,
                payments,
                selectedSftTier < 3, // universalMode
            );
        } else if (targetSftCount < stakedSftCounts[selectedSftTier]) {
            // Unstake SFT
            if (!stakedSftData) {
                toastError(ERROR_SC_DATA_NOT_LOADED);
                return;
            }
            if (!stakedSftData.can_unstake) {
                toastError(`You can unstake after ${convertSecondsToLocalDateTime(stakedSftData.unstake_timestamp)}.`);
                return;
            }

            const payments: TokenTransfer[] = [
                TokenTransfer.metaEsdtFromBigInteger(
                    sftCollection,
                    nonce,
                    stakedSftCounts[selectedSftTier] - targetSftCount,
                ),
            ];

            await unstakeSfts(
                farm.farm_address,
                payments,
                selectedSftTier < 3, // universalMode
            );
        }
    }

    if (!farm || !pool) {
        return <Loader />;
    }

    const firstTokenTicker = createTokenTicker(pool.first_token_id);
    const secondTokenTicker = createTokenTicker(pool.second_token_id);

    function onVestaSelected() {
        setMintOption(0);
        // setClaimOption(0);
    }

    function onSVestaSelected() {
        setMintOption(1);
        setVestaSleepingYears(20);
    }

    function onFVestaSelected() {
        setMintOption(2);
    }

    function onRVestaSelected() {
        setClaimOption(0);
        setMintOption(0);
    }

    function onCVestaSelected() {
        setClaimOption(1);
        setMintOption(3);
    }

    function onYearSliderSelected() {
        setMintOption(1);
        setClaimOption(0);
    }

    function getHighlightedMultiplier(multipler: number = 1) {
        const strings = Number(multipler.toFixed(4)).toString().split('.');
        return (
            <div>
                <span style={{ fontSize: '1.5rem' }}>{strings[0]}</span>
                <span style={{ fontSize: '1rem' }}>{strings[1] ? `.${strings[1]}` : ''}</span>
                <span style={{ fontSize: '1.5rem' }}>x</span>
            </div>
        );
    }

    function getStyledNumber(strNum: string) {
        const strings = strNum.split('.');

        return (
            <div>
                <span style={{ fontSize: '1.8rem' }}>{strings[0]}</span>
                <span style={{ fontSize: '1.3rem' }}>{strings[1] ? `.${strings[1]}` : ''}</span>
            </div>
        );
    }

    return (
        <div className="container" style={{ marginTop: '50px' }}>
            <div className="d-flex justify-content-center mt-4">
                <div className="vesta-first-container">
                    <div className="d-flex flex-column gap-3">
                        <div className="d-flex justify-content-start">
                            <button
                                className="go-back-button"
                                onClick={() => {
                                    navigate(`${routeNames.farms}`);
                                }}
                            >
                                <BiLeftArrowAlt />
                            </button>
                        </div>

                        <div className="d-flex justify-content-center mb-2">
                            <span style={{ color: '#F1DC46', fontSize: '1.5rem' }}>
                                {firstTokenTicker} - {secondTokenTicker} Farm
                            </span>
                        </div>

                        <FarmRowInfo pool={pool} farmId={farmId} farm={farm} hideNavigate={true} />

                        <Row className="gy-4 p-0 mt-0" style={{ fontSize: '1.1rem', color: '#98A1C0' }}>
                            <Col md={6} lg={3} className="d-flex flex-column">
                                <div className="farm_card_header position-relative">
                                    <img
                                        className="mb-1 me-1"
                                        style={{ width: '2.5rem' }}
                                        src={getTokenLogo(pool.first_token_id)}
                                    />
                                    {formatNumber(
                                        convertWeiToEsdt(pool.first_token_reserve, pool.first_token_decimals),
                                        0,
                                    )}{' '}
                                    vEGLD
                                    <InfoTooltip title="vEGLD Liquidity Pool Amount" />
                                </div>

                                <div className="farm_card_body mt-1">
                                    <Row style={{ rowGap: '6px' }}>
                                        <Col className="p-1" xs={6}>
                                            <div
                                                className="d-flex text-center align-items-center justify-content-center farm_card_individual_element position-relative"
                                                style={{ fontSize: '4rem', height: '8rem' }}
                                            >
                                                <FaWeightHanging />

                                                <InfoTooltip title="Weigthed vEGOLD Amount of the Farm. Equals Pool Vegold Amount multiplied by Pool Weight. This is the amount used when computing the weekly Pool Raw Vesta Allocations." />
                                            </div>
                                        </Col>
                                        <Col className="p-1" xs={6}>
                                            <div className="farm_card_individual_element d-flex justify-content-center align-items-center">
                                                <img
                                                    className="mb-1 me-1"
                                                    style={{ width: '2.5rem' }}
                                                    src={getTokenLogo(pool.first_token_id)}
                                                />
                                                {!!farmParameters &&
                                                    convertToShortString(
                                                        convertWeiToEsdt(
                                                            pool.first_token_reserve,
                                                            pool.first_token_decimals,
                                                        ).multipliedBy(farmParameters.pool_weight),
                                                    )}
                                            </div>
                                        </Col>

                                        <Col className="p-1" xs={6}>
                                            <div
                                                className="d-flex text-center align-items-center justify-content-center farm_card_individual_element position-relative"
                                                style={{ height: '8rem' }}
                                            >
                                                <img src={poolPotencyImage} style={{ height: '6rem', width: 'auto' }} />

                                                <InfoTooltip title="Pool Potency designates the liqudity size in vEGLD of the Liqudity Pool with the fixed thresholds." />
                                            </div>
                                        </Col>
                                        <Col className="p-1" xs={6}>
                                            <div className="d-flex flex-column gap-2" style={{ height: '100%' }}>
                                                <span className="farm_card_individual_element">Pool Potency</span>
                                                <span className="farm_card_individual_element">
                                                    {poolPotency == 'Diamond' && poolDiamond_carats > 1
                                                        ? `${poolDiamond_carats} Carat Diamond`
                                                        : poolPotency}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col className="p-1" xs={6}>
                                            <div
                                                className="d-flex text-center align-items-center justify-content-center farm_card_individual_element position-relative"
                                                style={{
                                                    fontSize: '3rem',
                                                    height: '8rem',
                                                    fontWeight: '600',
                                                }}
                                            >
                                                {['X', 'XC'].includes(farm.tier)
                                                    ? farm.tier
                                                    : Math.max(poolTier - 1, 0)}

                                                <InfoTooltip title="Pool Tier (Red VESTA Multiplier). Tier X Pools are Pools opened by Demiourgos Holdings. Tiers 0 through 7 are Pools opened by Token Owners. To increase the numbered Tier, Token Owners must own Elite-Auryn. Their Elite Account Tier then corresponds with the Pool Tier." />
                                            </div>
                                        </Col>
                                        <Col className="p-1" xs={6}>
                                            <div className="d-flex flex-column gap-2" style={{ height: '100%' }}>
                                                <span className="farm_card_individual_element">Pool Tier</span>
                                                <span
                                                    className="farm_card_individual_element"
                                                    style={{ color: '#ff9090' }}
                                                >
                                                    Tier M. {farmParameters?.tier_multiplier}x
                                                </span>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col md={6} lg={4} className="d-flex flex-column">
                                {/* <div className="farm_card_header">
                  <img className="mb-1 me-1" style={{ width: '2.5rem' }} src={vestaXLogo} />
                </div> */}

                                <div className="farm_swap_card_body mt-1">
                                    <Row style={{ rowGap: '6px' }}>
                                        <Col className="p-1" xs={12}>
                                            <div
                                                className="text-center"
                                                style={{ fontSize: '1.2rem', color: '#F1DC46' }}
                                            >
                                                Vesta SFT Staking
                                            </div>
                                        </Col>
                                        <Col className="p-1" xs={12}>
                                            <div className="d-flex gap-2">
                                                <div
                                                    className={
                                                        'farm_card_individual_element d-flex justify-content-center align-items-center w-20'
                                                    }
                                                >
                                                    Gold
                                                </div>
                                                <div
                                                    className={
                                                        'farm_card_individual_element d-flex justify-content-center align-items-center w-20'
                                                    }
                                                >
                                                    Silver
                                                </div>
                                                <div
                                                    className={
                                                        'farm_card_individual_element d-flex justify-content-center align-items-center w-20'
                                                    }
                                                >
                                                    Bronze
                                                </div>
                                                <div
                                                    className="farm_card_individual_element d-flex justify-content-center align-items-center position-relative"
                                                    style={{ width: '40%' }}
                                                >
                                                    {SftCountdown ? <SftCountdown /> : <CountdownCompleted />}

                                                    <InfoTooltip
                                                        title="Time until SFTs can be unstaked. Cooldown only starts after a reward claim event. When 'Unlockable' is shown, SFTs can be unstaked."
                                                        type={2}
                                                    />
                                                </div>
                                            </div>
                                            <div className="d-flex gap-2 mt-2">
                                                <div
                                                    className={clsx(
                                                        'sft-box d-flex justify-content-center align-items-center w-20 gold-color',
                                                        selectedSftTier == 0 && 'selected',
                                                    )}
                                                    onClick={() => onClickSftTier(0)}
                                                >
                                                    {stakedSftCounts[0]}
                                                </div>
                                                <div
                                                    className={clsx(
                                                        'sft-box d-flex justify-content-center align-items-center w-20 silver-color',
                                                        selectedSftTier == 1 && 'selected',
                                                    )}
                                                    onClick={() => onClickSftTier(1)}
                                                >
                                                    {stakedSftCounts[1]}
                                                </div>
                                                <div
                                                    className={clsx(
                                                        'sft-box d-flex justify-content-center align-items-center w-20 bronze-color',
                                                        selectedSftTier == 2 && 'selected',
                                                    )}
                                                    onClick={() => onClickSftTier(2)}
                                                >
                                                    {stakedSftCounts[2]}
                                                </div>
                                                <div
                                                    className="farm_card_individual_element d-flex justify-content-center align-items-center w-20 position-relative"
                                                    style={{ color: '#c45911', fontWeight: '500' }}
                                                >
                                                    UM
                                                    <InfoTooltip
                                                        title="Universal Multiplier (Orange VESTA Multiplier). The Universal Multiplier is a pool global multiplier, increasing the APR for all LP Farmers. It is increased by staking VESTA SFTs: Golden Vesta 1.00%, Silver Vesta 0.50%, Bronze Vesta 0.25%."
                                                        type={2}
                                                    />
                                                </div>
                                                <div
                                                    className="farm_card_individual_element d-flex justify-content-center align-items-center w-20"
                                                    style={{ color: '#c45911', fontWeight: '500' }}
                                                >
                                                    {farmParameters ? `${farmParameters.liquidity_multiplier}x` : '-'}
                                                </div>
                                            </div>

                                            <div className="d-flex gap-2 mt-2">
                                                <div
                                                    className={clsx(
                                                        'sft-box d-flex justify-content-center align-items-center w-20 gold-color',
                                                        selectedSftTier == 3 && 'selected',
                                                    )}
                                                    onClick={() => onClickSftTier(3)}
                                                >
                                                    {stakedSftCounts[3]}
                                                </div>
                                                <div
                                                    className={clsx(
                                                        'sft-box d-flex justify-content-center align-items-center w-20 silver-color',
                                                        selectedSftTier == 4 && 'selected',
                                                    )}
                                                    onClick={() => onClickSftTier(4)}
                                                >
                                                    {stakedSftCounts[4]}
                                                </div>
                                                <div
                                                    className={clsx(
                                                        'sft-box d-flex justify-content-center align-items-center w-20 bronze-color',
                                                        selectedSftTier == 5 && 'selected',
                                                    )}
                                                    onClick={() => onClickSftTier(5)}
                                                >
                                                    {stakedSftCounts[5]}
                                                </div>
                                                <div
                                                    className="farm_card_individual_element d-flex justify-content-center align-items-center w-20 position-relative"
                                                    style={{ color: '#00b050', fontWeight: '500' }}
                                                >
                                                    IM
                                                    <InfoTooltip
                                                        title="Individual Multiplier (Green Vesta Multiplier). The Individual Multiplier is a pool multiplier, that applies only to the individual that has staked the Vesta SFTs. In this way, each farmer can boost their own APR: Golden Vesta 5.00%, Silver Vesta 2.50%, Bronze Vesta 1.25%."
                                                        type={2}
                                                    />
                                                </div>
                                                <div
                                                    className="farm_card_individual_element d-flex justify-content-center align-items-center w-20"
                                                    style={{ color: '#00b050', fontWeight: '500' }}
                                                >
                                                    {farmParameters ? `${farmParameters.personal_multiplier}x` : '-'}
                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="p-1" xs={12}>
                                            <div className="d-flex gap-2">
                                                <div className="w-60 farm_card_individual_element d-flex justify-content-between align-items-center">
                                                    <button
                                                        className="spin-but"
                                                        onClick={() => onChangeTargetSftCount(targetSftCount - 1)}
                                                    >
                                                        {' '}
                                                        -{' '}
                                                    </button>
                                                    <span>
                                                        {targetSftCount}/
                                                        {stakedSftCounts[selectedSftTier] +
                                                            sftBalances[selectedSftTier % 3]}
                                                    </span>
                                                    <button
                                                        className="spin-but"
                                                        onClick={() => onChangeTargetSftCount(targetSftCount + 1)}
                                                    >
                                                        {' '}
                                                        +{' '}
                                                    </button>
                                                </div>

                                                <div className="w-40">
                                                    <button
                                                        className="farm_but w-100 h-100"
                                                        onClick={onSftStakeOrUnstake}
                                                    >
                                                        {targetSftCount > stakedSftCounts[selectedSftTier]
                                                            ? 'Stake'
                                                            : targetSftCount < stakedSftCounts[selectedSftTier]
                                                            ? 'Unstake'
                                                            : '-'}
                                                    </button>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row style={{ rowGap: '6px' }}>
                                        <Col className="p-1" xs={4}>
                                            <div className="farm_card_individual_element position-relative">
                                                Pool Weight
                                                <InfoTooltip title="Pool Weight represents the amount Pool vEGLD is multiplied with, when computing Pool Weekly Raw Vesta Allocations. Normally Pool Weight is neutral (1.0x), however Demiourgos Partners starting with the SILVER level, get increased Pool Weight Values. Later on, everyone will be able to increase their Pool's Weight by staking Golden Vesta SFTs in the 'Universal Mode'." />
                                            </div>
                                        </Col>
                                        <Col className="p-1" xs={4}>
                                            <div
                                                className="d-flex text-center align-items-center justify-content-center farm_card_individual_element"
                                                style={{ fontSize: '4rem', height: '100%' }}
                                            >
                                                <FaWeightHanging />
                                            </div>
                                        </Col>
                                        <Col className="p-1" xs={4}>
                                            <div
                                                className="farm_card_individual_element d-flex justify-content-center align-items-center"
                                                style={{ fontSize: '2.5rem' }}
                                            >
                                                {farmParameters?.pool_weight}x
                                            </div>
                                        </Col>

                                        <Col className="p-1" xs={4}>
                                            <div className="farm_card_individual_element position-relative">
                                                Pool Density
                                                <InfoTooltip title="Pool Density (Yellow VESTA Multiplier). The Pool Density represents an extra minting multiplier awarded to Demiourgos Partners, based on partnership Level, starting with BRONZE." />
                                            </div>
                                        </Col>
                                        <Col className="p-1" xs={4}>
                                            <div className="d-flex text-center align-items-center justify-content-center farm_card_individual_element h-100">
                                                <img src={densitySymbol} width={'40px'} />
                                            </div>
                                        </Col>
                                        <Col className="p-1" xs={4}>
                                            <div
                                                className="farm_card_individual_element d-flex justify-content-center align-items-center"
                                                style={{ fontSize: '2.5rem', color: '#bf8f00' }}
                                            >
                                                {poolDensity}x
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            <Col md={12} lg={5} className="d-flex flex-column">
                                <div className="farm_swap_card_body">
                                    <Row>
                                        <Col className="p-1 justify-content-" xs={4}>
                                            <button
                                                className="farm_card_individual_element_but w-100 position-relative"
                                                onClick={() => {
                                                    navigate(`${routeNames.pools}/add-liquidity?pool=${pool.index}`);
                                                }}
                                            >
                                                Add Liquidity
                                                <InfoTooltip title="Click here to add Liquidity in order to earn rewards with this Farm." />
                                            </button>
                                        </Col>
                                        <Col className="p-1" xs={8}>
                                            <div className="farm_card_individual_element d-flex flex-column text-right">
                                                <span>
                                                    {formatNumber(
                                                        getLPTokenRatio(
                                                            pool.lp_token_balance,
                                                            circulatingLpSupply,
                                                            pool.first_token_reserve,
                                                        ),
                                                    )}{' '}
                                                    /{' '}
                                                    {formatNumber(
                                                        convertWeiToEsdt(
                                                            availableVegldAmount,
                                                            pool.first_token_decimals,
                                                        ),
                                                    )}{' '}
                                                    vEGLD
                                                </span>
                                                <span>
                                                    {formatNumber(
                                                        getLPTokenRatio(
                                                            pool.lp_token_balance,
                                                            circulatingLpSupply,
                                                            pool.second_token_reserve,
                                                        ),
                                                    )}{' '}
                                                    /{' '}
                                                    {formatNumber(
                                                        convertWeiToEsdt(
                                                            available_token_amount,
                                                            pool.second_token_decimals,
                                                        ),
                                                    )}{' '}
                                                    {pool.second_token_ticker}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col className="p-1" xs={4}>
                                            <button
                                                className="farm_card_individual_element_but w-100 position-relative"
                                                onClick={() => {
                                                    navigate(`${routeNames.pools}/my-liquidity`);
                                                }}
                                            >
                                                Remove Liquidity
                                                <InfoTooltip title="Click here to remove Liquidity from this Farm." />
                                            </button>
                                        </Col>
                                        <Col className="p-1" xs={8}>
                                            <div className="farm_card_individual_element d-flex flex-column text-right">
                                                <span>
                                                    {totalExoticLps.toLocaleString()} /{' '}
                                                    {totalAccountPoolLps.toLocaleString()}
                                                </span>
                                                <span>
                                                    {pool.first_token_ticker}-{pool.second_token_ticker}-LP
                                                </span>
                                            </div>
                                        </Col>
                                        <Col className="p-1" xs={4}>
                                            <button
                                                className="farm_card_individual_element_but w-100 position-relative"
                                                onClick={() => {
                                                    navigate(`${routeNames.swap}`);
                                                }}
                                            >
                                                Swap
                                                <InfoTooltip title="Click here in order to swap between the Tokens of this Farm." />
                                            </button>
                                        </Col>
                                        <Col className="p-1" xs={8} />
                                        <Col className="p-1" xs={4}>
                                            <div className="farm_card_individual_element d-flex flex-column position-relative">
                                                <span>Wallet LIQ.</span>
                                                <span>Native LPs</span>

                                                <InfoTooltip title="Shows the Native LPs specific to this Farm. The Native LPs are the LPs generated when providing Liquidty. They must be staked in order to earn rewards with them." />
                                            </div>
                                        </Col>
                                        <Col className="p-1" xs={8}>
                                            <div className="farm_card_individual_element d-flex flex-column text-right">
                                                <span>
                                                    {formatNumber(
                                                        convertWeiToEsdt(pool.lp_token_balance, pool.lp_token_decimals),
                                                    )}
                                                </span>
                                                <span>
                                                    {pool.first_token_ticker}-{pool.second_token_ticker}-LP
                                                </span>
                                            </div>
                                        </Col>
                                        <Col className="p-1" xs={4}>
                                            <button
                                                className="farm_card_individual_element_but d-flex flex-column w-100 position-relative"
                                                onClick={() => setShowExoticLiqStakeBox(!showExoticLiqStakeBox)}
                                            >
                                                <span>Farming LIQ.</span>
                                                <span>Exotic LPs</span>

                                                <InfoTooltip title="Click here to expand or collapse the view showing the Exotic LPs. Exotic LPs represent the staked Native LPs, when they are staked using the 3 staking variants. Each Staking variant has a specific staking weight, depending on their staking status, and a specific unstaking time (until the native LP is returned)." />
                                            </button>
                                        </Col>
                                        <Col className="p-1" xs={8}>
                                            <div className="farm_card_individual_element d-flex flex-column text-right">
                                                <span>{totalVlpStaked.toLocaleString()}</span>
                                                <span>{'Virtual LP (VLP)'}</span>
                                            </div>
                                        </Col>
                                        {showExoticLiqStakeBox && (
                                            <StakeLpsBox
                                                pool={pool}
                                                farm={farm}
                                                exoticLps={farmTokens.map((v) => convertFTT2NFTBT(v))}
                                            />
                                        )}
                                    </Row>
                                </div>
                            </Col>

                            <Col xl={3} lg={4} md={12} style={{ flex: '1 1' }}>
                                <div className="farm_card_header position-relative h-50">
                                    <div>Primal Vesta Earnings</div>

                                    <div
                                        className="d-flex justify-content-between align-items-center mt-4"
                                        style={{ fontSize: '2.5rem' }}
                                    >
                                        <button
                                            className={clsx({
                                                'farm_card_individual_element_but d-flex flex-column justify-content-center align-items-center':
                                                    true,
                                                selected: claimOption === 0,
                                            })}
                                            onClick={onRVestaSelected}
                                        >
                                            <img src={rVestaXLogo} width={'40px'} />
                                        </button>

                                        <div className="d-flex align-items-center" style={{ fontSize: '1.8rem' }}>
                                            {getStyledNumber(formatNumber(convertWeiToEsdt(finalRewardAmount)))}
                                            &nbsp;
                                            <span style={{ fontSize: '1.5rem' }}>RAW</span>
                                        </div>
                                    </div>

                                    <div
                                        className="d-flex justify-content-between align-items-center mt-3"
                                        style={{ fontSize: '2.5rem' }}
                                    >
                                        <button
                                            className={clsx({
                                                'farm_card_individual_element_but d-flex flex-column justify-content-center align-items-center':
                                                    true,
                                                selected: claimOption === 1,
                                            })}
                                            onClick={onCVestaSelected}
                                        >
                                            <img src={cVestaXLogo} width={'40px'} />
                                        </button>

                                        <div className="d-flex align-items-center" style={{ fontSize: '1.8rem' }}>
                                            {`0`}
                                            &nbsp;
                                            <span style={{ fontSize: '1.5rem' }}>CND</span>
                                        </div>
                                    </div>

                                    <InfoTooltip title="The Primal Vesta Earnings are the Raw-Vesta and Condensed Vesta Amounts that have been earned so far. These amounts must be converted to one of the 4 Refined Vesta Variants in order to capitalize on the Farming and Staking Rewards." />
                                </div>

                                <div
                                    className="farm_card_body mt-1 position-relative"
                                    style={{ height: '35%', borderRadius: '0px' }}
                                >
                                    <div>To Claim:</div>

                                    <div
                                        className="d-flex align-items-center justify-content-center"
                                        style={{ fontSize: '1.5rem' }}
                                    >
                                        {mintPercent}%
                                    </div>

                                    <div
                                        className="d-flex align-items-center justify-content-center"
                                        style={{ fontSize: '2.5rem' }}
                                    >
                                        {claimOption == 0
                                            ? formatNumber(
                                                  convertWeiToEsdt(finalRewardAmount)
                                                      .multipliedBy(mintPercent)
                                                      .div(100),
                                              )
                                            : 0}
                                        &nbsp;
                                        <img src={claimOption == 0 ? rVestaXLogo : cVestaXLogo} width={'40px'} />
                                    </div>

                                    <CustomSlider
                                        value={mintPercent}
                                        marks={vestaRewardsSliderMarks}
                                        onChange={setMintPercent}
                                    />

                                    <InfoTooltip title="The Amount of Primal Vesta Variant (Raw or Condensed) that is primed for minting. Users can select individual percentages from their Primal Vesta Rewards as input for minting one of the 4 Refined Vesta Variants." />
                                </div>

                                <div className="farm_card_body mt-1 position-relative" style={{ height: '14%' }}>
                                    <div
                                        className="d-flex text-center justify-content-center align-items-center"
                                        style={{ fontSize: '1.5rem', color: 'rgb(152, 161, 192)' }}
                                    >
                                        TVL{' '}
                                        {convertToDollarString(
                                            convertWeiToEsdt(pool.first_token_reserve, pool.first_token_decimals)
                                                .multipliedBy(getTokenPrice(pool.first_token_id))
                                                .multipliedBy(2),
                                        )}
                                        <InfoTooltip title="Total Value Locked" />
                                    </div>
                                </div>
                            </Col>

                            <Col xl={9} lg={8} md={12}>
                                <div className="farm_swap_card_body h-100">
                                    <Row className="align-items-center gy-4">
                                        <Col className="p-1" sm={12}>
                                            <Row className="g-4">
                                                <Col
                                                    className="d-flex justify-content-center"
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={2}
                                                >
                                                    <button
                                                        className={clsx(
                                                            'farm_card_individual_element_but d-flex flex-column justify-content-center align-items-center',
                                                            mintOption === 0 && 'selected',
                                                        )}
                                                        onClick={onVestaSelected}
                                                        disabled={claimOption == 1}
                                                    >
                                                        <img className="farm_logo_element mb-1" src={vestaXLogo} />
                                                        <span>VESTA</span>
                                                    </button>
                                                </Col>

                                                <Col
                                                    className="d-flex justify-content-center"
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={2}
                                                >
                                                    <button
                                                        className={clsx(
                                                            'farm_card_individual_element_but d-flex flex-column justify-content-center align-items-center',
                                                            mintOption === 3 && 'selected',
                                                        )}
                                                        onClick={() => setMintOption(3)}
                                                        disabled={claimOption == 0}
                                                    >
                                                        <img className="farm_logo_element mb-1" src={bVestaXLogo} />
                                                        <span>bVESTA</span>
                                                    </button>
                                                </Col>

                                                <Col
                                                    className="d-flex justify-content-center"
                                                    xs={12}
                                                    sm={12}
                                                    md={12}
                                                    lg={12}
                                                    xl={4}
                                                >
                                                    <div className="d-flex flex-column gy-2 text-center">
                                                        <div style={{ fontSize: '1.3rem', color: '#ffe72b' }}>
                                                            {'??? Liquidity Provider'}
                                                        </div>
                                                        <div>
                                                            Pool Dominance Point:{' '}
                                                            <span style={{ color: '#ef4545' }}>???</span> ‰
                                                        </div>
                                                        <div>
                                                            Account Type: ??? (
                                                            <span style={{ color: '#ef4545' }}>???</span> x)
                                                        </div>
                                                        <div>
                                                            Elite Tier Modifier (
                                                            <span style={{ color: '#ef4545' }}>???</span> x)
                                                        </div>
                                                        <div>
                                                            Account Dominance Point:{' '}
                                                            <span style={{ color: '#ef4545' }}>???</span> ‰
                                                        </div>
                                                        <div>
                                                            Current Account Liquidity:{' '}
                                                            <span style={{ color: '#ef4545' }}>???</span> ‰
                                                        </div>
                                                        <div>
                                                            RAW/CND-VST Split:{' '}
                                                            <span style={{ color: '#ef4545' }}>???</span> % /{' '}
                                                            <span style={{ color: '#ef4545' }}>???</span> %
                                                        </div>
                                                    </div>
                                                </Col>

                                                <Col
                                                    className="d-flex justify-content-center"
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={2}
                                                >
                                                    <button
                                                        className={clsx(
                                                            'farm_card_individual_element_but d-flex flex-column justify-content-center align-items-center',
                                                            mintOption === 1 && 'selected',
                                                        )}
                                                        onClick={onSVestaSelected}
                                                        disabled={claimOption == 1}
                                                    >
                                                        <img className="farm_logo_element mb-1" src={sVestaXLogo} />
                                                        <span>sVESTA</span>
                                                    </button>
                                                </Col>

                                                <Col
                                                    className="d-flex justify-content-center"
                                                    xs={6}
                                                    sm={6}
                                                    md={6}
                                                    lg={6}
                                                    xl={2}
                                                >
                                                    <button
                                                        className={clsx({
                                                            'farm_card_individual_element_but d-flex flex-column justify-content-center align-items-center':
                                                                true,
                                                            selected: mintOption === 2,
                                                            farm_card_individual_element_but_red_out:
                                                                farmRedux.eliteAccountTier < 4,
                                                        })}
                                                        onClick={onFVestaSelected}
                                                        disabled={claimOption == 1 || farmRedux.eliteAccountTier < 4}
                                                    >
                                                        <img className="farm_logo_element mb-1" src={fVestaXLogo} />
                                                        <span>fVESTA</span>
                                                    </button>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col className="p-1" sm={12}>
                                            <button
                                                className={clsx(
                                                    'farm_card_individual_element_block w-100',
                                                    mintOption === 1 && 'selected',
                                                )}
                                                onClick={onYearSliderSelected}
                                                // disabled={claimOption == 1}
                                            >
                                                <div className="d-flex justify-content-center">
                                                    <span>{vestaSleepingYears} years</span>
                                                </div>
                                                <CustomSlider
                                                    value={vestaSleepingYears}
                                                    marks={false}
                                                    onChange={onChangeVestaSleepingYearsSlider}
                                                    min={1}
                                                    max={20}
                                                    step={1}
                                                    disabled={mintOption !== 1}
                                                />
                                                <div className="d-flex justify-content-center">
                                                    <span>sVESTA</span>
                                                </div>
                                            </button>
                                        </Col>

                                        <Col className="p-1" sm={12}>
                                            <div className="farm-multipler-box farm-final-result position-relative">
                                                {/* <div>{'VsFM ='}</div> */}

                                                <div
                                                    className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box"
                                                    style={{ color: '#ffc90e' }}
                                                >
                                                    <span>DEB</span>
                                                    {getHighlightedMultiplier(farmRedux.deb)}

                                                    <InfoTooltip title="Demiourgos Elite Boost, scales with Demiourgos Elite Account Tier" />
                                                </div>

                                                <div
                                                    className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box"
                                                    style={{ color: '#7030a0' }}
                                                >
                                                    <span>VLM</span>
                                                    {getHighlightedMultiplier()}

                                                    <InfoTooltip title="Vesta Loyalty Multiplier. Increases with the Vesta Loyalty Score (Scales with Demiourgos Elite Account Tier). Vesta Loyalty Score increases each day Vesta SFTs are kept in staking. Unstaking resets the Vesta Loyalty Score to zero, droping the VLM to 1x." />
                                                </div>

                                                <div
                                                    className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box"
                                                    style={{ color: '#ff9090' }}
                                                >
                                                    <span>TM</span>
                                                    {getHighlightedMultiplier(farmParameters?.tier_multiplier)}
                                                    {/* <span style={{ fontSize: '1.5rem' }}>
                            {farmParameters?.tier_multiplier}x
                          </span> */}

                                                    <InfoTooltip title="Tier Multiplier (Red VESTA Multiplier)" />
                                                </div>

                                                <div
                                                    className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box"
                                                    style={{ color: '#bf8f00' }}
                                                >
                                                    <span>D</span>
                                                    {getHighlightedMultiplier(poolDensity)}
                                                    {/* <span style={{ fontSize: '1.5rem' }}>{poolDensity}x</span> */}

                                                    <InfoTooltip title="Density Multiplier (Yellow VESTA Multiplier)" />
                                                </div>

                                                <div
                                                    className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box"
                                                    style={{ color: '#c45911' }}
                                                >
                                                    <span>UM</span>
                                                    {getHighlightedMultiplier(farmParameters?.liquidity_multiplier)}
                                                    {/* <span style={{ fontSize: '1.5rem' }}>
                            {farmParameters?.liquidity_multiplier}x
                          </span> */}

                                                    <InfoTooltip title="Universal Multiplier (Orange VESTA Multiplier)" />
                                                </div>

                                                <div
                                                    className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box"
                                                    style={{ color: '#00b050' }}
                                                >
                                                    <span>IM</span>
                                                    {getHighlightedMultiplier(farmParameters?.personal_multiplier)}
                                                    {/* <span style={{ fontSize: '1.5rem' }}>
                            {farmParameters?.personal_multiplier}x
                          </span> */}

                                                    <InfoTooltip title="Individual Multiplier (Green Vesta Multiplier)" />
                                                </div>

                                                <div
                                                    className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box"
                                                    style={{ color: '#1cc9f1' }}
                                                >
                                                    <span>DM</span>
                                                    {getHighlightedMultiplier(farmParameters?.diamond_multiplier)}
                                                    {/* <span style={{ fontSize: '1.5rem' }}>
                            {farmParameters?.diamond_multiplier}x
                          </span> */}

                                                    <InfoTooltip title="Diamond Multiplier (Purple Vesta Multiplier). Each Carat a Diamond Pool obtains starting with 20000 vEGLD, creates a non-neutral Diamond Multiplier. Example: A 2 Carat Diamond Pool has a 1.2x DM, while a 17 Carat Diamond Pool would have a 2.7x DM." />
                                                </div>

                                                <div
                                                    className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box"
                                                    style={{ color: '#0070c0' }}
                                                >
                                                    <span>VM</span>
                                                    {getHighlightedMultiplier(vestaMultiplier)}
                                                    {/* <span style={{ fontSize: '1.5rem' }}>
                            {vestaMultiplier}x
                          </span> */}

                                                    <InfoTooltip title="Vesta Multiplier (Blue Vesta Multiplier). The Multiplier given by the Vesta Variant chosen to be minted. Minting pure VESTA makes for a neutral multiplier of 1x. Selecting Sleeping Vesta with 1 to 20 years of sleep. Frozen Vesta represents a completely locked down VESTA Variant. It can be created only by Demiourgos Elite Accounts starting with Tier 3. In this case, the Vesta Multiplier is based on the Demiourgos Elite Account Tier." />
                                                </div>

                                                <div>=</div>

                                                <div
                                                    className="d-flex flex-column align-items-center position-relative farm_card_individual_element_box"
                                                    // style={{ color: '#0070c0' }}
                                                >
                                                    <span>VTM</span>
                                                    {getHighlightedMultiplier(
                                                        farmParameters
                                                            ? farmRedux.deb *
                                                                  farmParameters.tier_multiplier *
                                                                  farmParameters.liquidity_multiplier *
                                                                  farmParameters.diamond_multiplier *
                                                                  poolDensity *
                                                                  farmParameters.personal_multiplier *
                                                                  vestaMultiplier
                                                            : 1,
                                                    )}

                                                    <InfoTooltip title="The VTM or the Vesta Total Multiplier, determines the amount of Refined Vesta Variant that will be created from the selected amount of Primal Vesta Variant used as input. The higher it gets, the more that specific Variant of Refined Vesta will be minted" />
                                                </div>
                                            </div>
                                        </Col>

                                        <Col className="p-1" sm={12}>
                                            <div className="d-flex justify-content-between align-items-center gap-2">
                                                <img
                                                    src={
                                                        mintOption === 0
                                                            ? vestaXLogo
                                                            : mintOption === 1
                                                            ? sVestaXLogo
                                                            : mintOption === 2
                                                            ? fVestaXLogo
                                                            : bVestaXLogo
                                                    }
                                                    width={'40px'}
                                                />
                                                <div className="d-flex justify-content-between w-100 farm_card_individual_element h-100">
                                                    <span>
                                                        {mintOption === 0
                                                            ? 'VESTA'
                                                            : mintOption === 1
                                                            ? 'Sleeping VESTA'
                                                            : mintOption === 2
                                                            ? 'Frozen VESTA'
                                                            : 'Blessed VESTA'}
                                                    </span>
                                                    <span>
                                                        {claimOption === 0
                                                            ? farmParameters
                                                                ? formatNumber(
                                                                      convertWeiToEsdt(finalRewardAmount)
                                                                          .multipliedBy(mintPercent)
                                                                          .div(100)
                                                                          .multipliedBy(
                                                                              farmRedux.deb *
                                                                                  farmParameters.tier_multiplier *
                                                                                  farmParameters.liquidity_multiplier *
                                                                                  farmParameters.diamond_multiplier *
                                                                                  poolDensity *
                                                                                  farmParameters.personal_multiplier *
                                                                                  vestaMultiplier,
                                                                          ),
                                                                  )
                                                                : '0'
                                                            : 0}
                                                    </span>
                                                </div>
                                                <button
                                                    className="farm_but w-20 py-3"
                                                    onClick={onMintVesta}
                                                    disabled={
                                                        // (mintOption != 2 || farmRedux.eliteAccountTier < 4) && // TODO: uncoment to enable minting fVesta
                                                        mintOption != 1 && mintOption != 0
                                                    }
                                                >
                                                    Mint
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>

                            {/* <Col md={12}>
                <div className="farm_swap_card_body py-2">
                  <Row>
                    <Col className="p-1" md={4}>
                      <div
                        className="farm_card_individual_element position-relative"
                        style={{ fontSize: '1.875rem' }}
                      >
                        TVL {' '}
                        {
                          convertToDollarString(
                            convertWeiToEsdt(pool.first_token_reserve, pool.first_token_decimals)
                              .multipliedBy(getTokenPrice(pool.first_token_id))
                              .multipliedBy(2),
                          )
                        }

                        <InfoTooltip title='Total Value Locked' />
                      </div>
                    </Col>
                    <Col className="p-1" md={8}>
                      <div className="farm_card_individual_element farm-final-result position-relative">
                        <div>{'VsFM ='}</div>

                        <div
                          className="d-flex flex-column align-items-center position-relative"
                          style={{ color: '#ffc90e' }}
                        >
                          <span>DEB</span>
                          <span style={{ fontSize: '1.5rem' }}>
                            {farmRedux.deb}x
                          </span>

                          <InfoTooltip title='Demiourgos Elite Boost, scales with Demiourgos Elite Account Tier' type={1} />
                        </div>
                        *
                        <div
                          className="d-flex flex-column align-items-center position-relative"
                          style={{ color: '#7030a0' }}
                        >
                          <span>VLM</span>
                          <span style={{ fontSize: '1.5rem' }}>
                            1x
                          </span>

                          <InfoTooltip title='Vesta Loyalty Multiplier. Increases with the Vesta Loyalty Score (Scales with Demiourgos Elite Account Tier). Vesta Loyalty Score increases each day Vesta SFTs are kept in staking. Unstaking resets the Vesta Loyalty Score to zero, droping the VLM to 1x.' type={1} />
                        </div>
                        *
                        <div
                          className="d-flex flex-column align-items-center position-relative"
                          style={{ color: '#ff9090' }}
                        >
                          <span>TM</span>
                          <span style={{ fontSize: '1.5rem' }}>
                            {farmParameters?.tier_multiplier}x
                          </span>

                          <InfoTooltip title='Tier Multiplier (Red VESTA Multiplier)' type={1} />
                        </div>
                        *
                        <div
                          className="d-flex flex-column align-items-center position-relative"
                          style={{ color: '#bf8f00' }}
                        >
                          <span>D</span>
                          <span style={{ fontSize: '1.5rem' }}>{poolDensity}x</span>

                          <InfoTooltip title='Density Multiplier (Yellow VESTA Multiplier)' type={1} />
                        </div>
                        *
                        <div
                          className="d-flex flex-column align-items-center position-relative"
                          style={{ color: '#c45911' }}
                        >
                          <span>UM</span>
                          <span style={{ fontSize: '1.5rem' }}>
                            {farmParameters?.liquidity_multiplier}x
                          </span>

                          <InfoTooltip title='Universal Multiplier (Orange VESTA Multiplier)' type={1} />
                        </div>
                        *
                        <div
                          className="d-flex flex-column align-items-center position-relative"
                          style={{ color: '#00b050' }}
                        >
                          <span>IM</span>
                          <span style={{ fontSize: '1.5rem' }}>
                            {farmParameters?.personal_multiplier}x
                          </span>

                          <InfoTooltip title='Individual Multiplier (Green Vesta Multiplier)' type={1} />
                        </div>
                        *
                        <div
                          className="d-flex flex-column align-items-center position-relative"
                          style={{ color: '#1cc9f1' }}
                        >
                          <span>DM</span>
                          <span style={{ fontSize: '1.5rem' }}>
                            {farmParameters?.diamond_multiplier}x
                          </span>

                          <InfoTooltip title='Diamond Multiplier (Purple Vesta Multiplier). Each Carat a Diamond Pool obtains starting with 20000 vEGLD, creates a non-neutral Diamond Multiplier. Example: A 2 Carat Diamond Pool has a 1.2x DM, while a 17 Carat Diamond Pool would have a 2.7x DM.' type={1} />
                        </div>
                        *
                        <div
                          className="d-flex flex-column align-items-center position-relative"
                          style={{ color: '#0070c0' }}
                        >
                          <span>VM</span>
                          <span style={{ fontSize: '1.5rem' }}>
                            {vestaMultiplier}x
                          </span>

                          <InfoTooltip title='Vesta Multiplier (Blue Vesta Multiplier). The Multiplier given by the Vesta Variant chosen to be minted. Minting pure VESTA makes for a neutral multiplier of 1x. Selecting Sleeping Vesta with 1 to 20 years of sleep. Frozen Vesta represents a completely locked down VESTA Variant. It can be created only by Demiourgos Elite Accounts starting with Tier 3. In this case, the Vesta Multiplier is based on the Demiourgos Elite Account Tier.' type={1} />
                        </div>
                        <div>{farmParameters ? `= ${(
                          farmRedux.deb *
                          farmParameters.tier_multiplier *
                          farmParameters.liquidity_multiplier *
                          farmParameters.diamond_multiplier *
                          poolDensity *
                          farmParameters.personal_multiplier *
                          vestaMultiplier
                        ).toLocaleString()}x` : '1x'}</div>

                        <InfoTooltip title='The VsFM or the Vesta Farm Multiplier, determines the amount of Vesta Variant that will be created from the selected amount of Raw Vesta, used as input. The higher it gets, the more that specific Variant of Vesta will be minted.' />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col> */}
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    );
};
